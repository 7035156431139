@import './variable';

.btn {
  font-size: 0.875rem;
  border-radius: 10px;
}
.btn-primary,
.btn-primary:disabled {
  background-color: $primary-600-color;
  border-color: $primary-600-color;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: $primary-900-color !important;
  border-color: $primary-900-color;
}
.btn-submit {
  height: 46px;
  border-radius: 30px;
  font-weight: 600;
}
.btn-secondary,
.btn-secondary:disabled {
  color: $primary-800-color;
  background-color: $primary-100-color;
  border-color: $primary-100-color;
}
.btn-secondary:hover,
.btn-secondary:focus {
  color: $primary-800-color;
  background-color: $primary-200-color !important;
  border-color: $primary-200-color;
}
.btn-accent,
.btn-accent:disabled {
  color: $accent-800-color;
  background-color: $accent-100-color;
  border-color: $accent-100-color;
}
.btn-accent:hover,
.btn-accent:focus {
  color: $accent-800-color;
  background-color: $accent-200-color !important;
  border-color: $accent-200-color;
}
.btn-accent:focus {
  box-shadow: 0 0 0 0.2rem $accent-100-color !important;
}
.form-control,
.form-select {
  color: #212221;
  background-color: $primary-50-color;
  font-size: 0.8rem;
  font-weight: 500;
  border: 0;
  border-radius: 10px;
  height: 46px;
  border: 1px solid $primary-200-color;
}
.form-control:focus,
.form-select:focus {
  background-color: $primary-50-color;
  color: #212221;
  box-shadow: 0 0 0 0.2rem rgba(79, 123, 246, 0.25);
}
.form-control:disabled,
.form-select:disabled,
.form-control[readonly] {
  background-color: $primary-100-color;
}
.btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(79, 123, 246, 0.25);
}
.btn-white {
  background-color: white !important;
  border: 1px solid $primary-600-color;
  font-size: 0.8rem;
  font-weight: 600;
}
.btn-white:hover,
.btn-white:focus {
  background-color: $primary-100-color !important;
  border: 1px solid $primary-800-color !important;
}
.dropdown-menu {
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 10px;

  .dropdown-item {
    color: #212221;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .dropdown-item:hover {
    background-color: $primary-50-color;
  }
  .dropdown-item.disabled {
    color: #6e7072;
  }
}

.cari-textinput {
  max-width: 500px;
}
