@font-face {
  font-family: 'Bahnschrift';
  src: local('Bahnschrift'), url(../fonts/BAHNSCHRIFT.TTF) format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.fw-normal {
  font-weight: 500 !important;
}
