.bg-background {
  background-color: #fafafb !important;
}

.bg-white-100 {
  background-color: #ffffff !important;
}

.bg-gray-10 {
  background-color: rgba(0, 0, 0, 0.16) !important;
}

.bg-black-20 {
  background-color: #393535 !important;
}

.bg-blue-100 {
  background-color: #001b46 !important;
}

.bg-black-40 {
  background-color: #8f8f8f !important;
}

.bg-black-60 {
  background-color: #858585 !important;
}

.bg-blue-80 {
  background-color: #282560 !important;
}

.bg-white-80 {
  background-color: #f1f1f1 !important;
}

.bg-gray-80 {
  background-color: #575454 !important;
}

.bg-black-100 {
  background-color: #0e132b !important;
}

.bg-white-60 {
  background-color: #e3e0e0 !important;
}

.bg-white-40 {
  background-color: #dbdbdb !important;
}

.bg-green-100 {
  background-color: #069731 !important;
}

.bg-orange-100 {
  background-color: #f37a20 !important;
}

.bg-red-100 {
  background-color: #f11a1a !important;
}

.bg-primary-50 {
  background-color: #f5f8ff !important;
}

.bg-slate {
  background-color: #899ecc !important;
}
