.action-button-dropdown {
  padding: 0;
  overflow: hidden;
  margin-top: 4px;

  .dropdown-item {
    font-size: 12px;
    height: 40px;
    display: flex;
    align-items: center;
  }
}
