@import './fonts';

@import './background';

@import './color';

@import './forms';

@import './table';

@import './badge';

@import './buttons';

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bfcdf1;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aab7dc;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-responsive {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.swal-modal {
  min-width: 300px;
  max-width: 500px;
  width: auto;
  border-radius: 18px;
  padding: 0 32px;
}
.swal-title {
  font-size: 18px;
  font-weight: 600;
  padding: 0;
}
.swal-text {
  text-align: center;
  max-width: none;
}
.swal-footer {
  text-align: center;
}
.swal-icon {
  transition: 1s ease;
  transform: scale(0.9);
}
.swal-button--loading,
.swal-button--loading:hover {
  color: transparent !important;
}

.swal-content-left {
  .swal-title,
  .swal-text {
    padding-left: 0;
    text-align: left;
  }
  .swal-footer {
    text-align: right;
  }
}

.information-box {
  font-size: 12px;
  font-weight: 500;
  background-color: #f9f9f9;
  border-radius: 10px;

  :last-child {
    margin-bottom: 0 !important;
  }

  .with-divider {
    border-bottom: 1.2px solid #e0e0e0;
  }
  .with-space {
    padding: 0.625rem 0;
  }
}
