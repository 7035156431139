@import '../../../assets/scss/variable';

.sgb-radio {
  .form-check {
    padding-right: 30px;
    &.form-check-row {
      display: inline-block;
      // margin-left: 1rem;
    }
    &.form-check-column {
      display: block;
    }
  }
  .form-check-input {
    height: 16px !important;
    width: 16px;
  }
  .form-check-input:checked {
    background-color: $primary-600-color;
    border-color: $primary-400-color;
  }
  .form-check-label {
    margin-bottom: 0 !important;
    color: #212221 !important;
    vertical-align: middle;
  }
  .form-label {
    margin-bottom: 8px !important;
  }
}
