@import '../../assets/scss/variable';

* {
  font-family: 'Inter';
}

a {
  text-decoration: none;
}

.flex-1 {
  flex: 1;
}

.error-input {
  border-color: red;
}

.green {
  color: green !important;
}

.green-2 {
  color: #19a134 !important;
}

.red {
  color: red !important;
}

.orange {
  color: #f57f21 !important;
}

.black {
  color: #0e132b !important;
}

.grey {
  color: #858585 !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.bold {
  font-weight: bold;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.content {
  transition: 0.2s;
  .main-content {
    margin-top: $header-height + $header-padding-top;
    margin-bottom: $header-padding-top;
    padding-left: $space;
    padding-right: $space;
    @media screen and (max-width: 1024px) {
      padding-left: $space-mobile;
      padding-right: $space-mobile;
    }
  }
}

.dual-table {
  .table-heading {
    font-size: 12px;
    color: #0e132b;
  }

  .table-row {
    font-size: 12px;
    word-wrap: break-word;
  }

  .no {
    width: 10%;
  }
}

.nav-tab {
  padding-bottom: 14px;
  flex-wrap: nowrap;
  overflow-x: auto;

  .item {
    margin-right: 36px;
    color: #575454;
    letter-spacing: 0px;
    z-index: 1;
    white-space: nowrap;
  }

  .item.selected {
    border-bottom-width: 3px;
    padding-bottom: 10px;
    border-bottom-color: $primary-800-color;
    border-bottom-style: solid;
    color: #0e132b;
    font-weight: bold;
  }
}
.nav-tab::-webkit-scrollbar {
  display: none;
}
