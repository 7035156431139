.calendar .react-datepicker__input-container input {
  position: relative;
}
.calendar .react-datepicker__input-container::after {
  content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.25 3.75H13.5V2.25C13.5 2.05109 13.421 1.86032 13.2803 1.71967C13.1397 1.57902 12.9489 1.5 12.75 1.5C12.5511 1.5 12.3603 1.57902 12.2197 1.71967C12.079 1.86032 12 2.05109 12 2.25V3.75H6V2.25C6 2.05109 5.92098 1.86032 5.78033 1.71967C5.63968 1.57902 5.44891 1.5 5.25 1.5C5.05109 1.5 4.86032 1.57902 4.71967 1.71967C4.57902 1.86032 4.5 2.05109 4.5 2.25V3.75H3.75C3.15326 3.75 2.58097 3.98705 2.15901 4.40901C1.73705 4.83097 1.5 5.40326 1.5 6V6.75H16.5V6C16.5 5.40326 16.2629 4.83097 15.841 4.40901C15.419 3.98705 14.8467 3.75 14.25 3.75Z' fill='%23ACC2F3'/%3E%3Cpath d='M1.5 14.25C1.5 14.8467 1.73705 15.419 2.15901 15.841C2.58097 16.2629 3.15326 16.5 3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.841C16.2629 15.419 16.5 14.8467 16.5 14.25V8.25H1.5V14.25Z' fill='%23ACC2F3'/%3E%3C/svg%3E%0A");
  position: absolute;
  right: 16px;
  top: calc(50%);
  transform: translateY(calc(-50%));
  height: 18px;
}
