@import '../../../assets/scss/variable';

.input-image {
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: $primary-200-color;
  flex: 1;
  height: 240px;
  background: #f5f8ff;
  overflow: hidden;

  .image {
    flex: 1;
    height: inherit;
    border-radius: inherit;
    object-fit: contain;
  }
}
