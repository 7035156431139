@import '../../../assets/scss/variable';
.modal {
  --bs-modal-padding: 2.5rem 2.188rem;
}
.volta-modal {
  .modal-content {
    border-radius: 20px;
    padding: 0px 12px;

    .modal-body {
      .volta-modal-header {
        margin: 0;
        position: relative;

        .btn {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .volta-modal-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding-top: 6px;
      }

      .volta-modal-body {
        margin-top: 20px;

        .form-group {
          margin-bottom: 20px;

          label {
            color: #858585;
            font-size: 12px;
            margin-bottom: 12px;
          }
        }

        .btn-modal-submit {
          background-color: $primary-600-color;
          color: white;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 46px;
          margin-top: 47px;
          border-radius: 30px;
        }
        .btn-modal-submit:hover {
          background-color: $primary-900-color;
        }
      }
    }
  }
}
