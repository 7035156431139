$header-height: 60px;
$header-padding-top: 30px;
$sidebar-width: 260px;
$space: 32px;
$space-mobile: 12px;
$primary-900-color: rgba(42, 70, 146, 1);
$primary-800-color: rgba(50, 92, 205, 1);
$primary-600-color: rgba(79, 123, 246, 1); // this is primary color (#4F7BF6)
$primary-500-color: rgb(109, 147, 250);
$primary-400-color: rgb(145, 174, 252);
$primary-300-color: rgb(183, 201, 250);
$primary-200-color: rgb(208, 218, 245);
$primary-100-color: rgb(245, 248, 255);
$primary-50-color: rgb(251, 252, 255);
$accent-800-color: rgba(252, 111, 0, 1);
$accent-200-color: rgb(241, 210, 185);
$accent-100-color: rgba(255, 233, 216, 1);
$gray-800-color: rgb(33, 34, 33);
