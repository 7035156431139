@import './variable.scss';

.volta-table,
.stb-table {
  border: 1px solid rgba(127, 146, 135, 0.2);
  border-radius: 6px 6px 15px 15px;
  border-radius: 5px;
  overflow-x: auto;

  .react-bs-table-no-data {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #7e7e7e;

    padding-top: 20px;
    padding-bottom: 20px;

    @media screen and (max-width: 543px) {
      font-size: 10px;
    }
    @media screen and (max-width: 280px) {
      font-size: 8px;
    }
  }

  table {
    table-layout: auto;
    margin-bottom: 0;
  }

  .table-heading {
    th {
      color: $primary-900-color;
      font-size: 0.75rem;
      font-weight: 600;
      border-bottom: 0;
      min-height: 60px;
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;
      background: #f6f6f6;
    }

    th:after {
      height: 60%;
      width: 1px;
      position: absolute;
      top: 20%;
      bottom: 0;
      right: 0;
      content: '';
      background-color: rgba(127, 146, 135, 0.2);
    }

    th:last-child:after {
      background-color: transparent;
    }
  }

  .table-row {
    line-height: 1.5;
    color: #424942 !important;
    font-size: 0.75rem;
    font-weight: 600;

    td {
      border-bottom: 0;
      height: 54px;
      vertical-align: middle;
    }

    p {
      margin-bottom: 0;
    }

    .text-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
    }
  }

  .table-row:not(:last-child) {
    border-bottom: 1px solid rgba(127, 146, 135, 0.2);
  }
}

.table-content {
  .cari-title {
    margin-right: 20px;
    color: #575454;
    margin-bottom: 0;
    letter-spacing: 0px;
    padding-top: 6px;
  }

  .search-and-filter {
    display: flex;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .search-form-group {
    position: relative;
    flex: 1;

    img {
      position: absolute;
      top: 33%;
      z-index: 1;
      left: 20px;
      width: 13px;
      height: 13px;
    }

    input {
      width: 480px;
      background-color: $primary-50-color;
      color: $primary-600-color;
      border-radius: 24px;
      padding: 12px 20px;
      position: relative;
      padding-left: 46px;
      border: 1px solid $primary-100-color;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    input::placeholder {
      color: $primary-600-color;
    }
  }

  .filter-button {
    background-color: $primary-100-color;
    border-radius: 100%;
    border: 1px solid $primary-100-color;
    position: relative;
  }

  .filter-button:hover {
    background-color: $primary-100-color;
    border: 1px solid $primary-200-color;
  }

  .filter-indicator {
    background-color: #f11a1a;
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .template-container {
    display: flex;
    align-items: center;
    margin-left: 35px;
    column-gap: 14px;
    .template-btn {
      display: flex;
      column-gap: 4px;
      align-items: center;
      background-color: $primary-100-color;
      border: 1px solid $primary-100-color;
      font-size: 12px;
      color: #212121;
      border-radius: 10px;
      font-weight: 500;
      height: 36px;
    }
    .template-btn:hover {
      background-color: $primary-100-color;
      border: 1px solid $primary-200-color;
    }
  }

  .cari-button,
  .add-xls {
    background-color: #ff8000;
    border-radius: 5px;
  }

  .cari-button {
    width: 40px;
    height: 40px;
    margin-left: 12px;
    border: none;
  }

  .add-xls {
    color: white;
    padding: 0;
    margin-left: 10px;

    p {
      font-size: 14px;
      margin: 0;
    }
  }

  .add-button {
    font-size: 0.75rem;
    padding: 9px 18px;
    font-weight: 600;
    background-color: $primary-800-color;
    color: white;
    border-radius: 30px;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .plus {
      height: 14px;
      margin-right: 6px;
    }

    span {
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  .add-xls {
    width: 170px;
    height: 40px;
    // margin-left: 8px;
    padding-right: 14px;
    padding-left: 12.24px;
  }
}

.detail-link {
  color: #4f7bf6;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.delete-link {
  color: #ff5353;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 6px;
  margin-right: 6px;
}

.success-text,
.warning-text,
.unknown-text,
.failed-text {
  font-weight: bold;
  font-size: 12px;
  border-radius: 14px;
  white-space: nowrap;
  padding: 4px 13px;
  display: inline-block;
}

.success-text {
  color: #0a7d3a;
  background: #f2fdf6;
}

.warning-text {
  color: #ff8a00;
  background: #fff4e7;
}

.failed-text {
  color: #ff5353;
  background: #fff0f0;
}

.unknown-text {
  color: #858585;
  background: #f7f7f7;
}

.filter-content {
  width: 100%;
  height: 90%;

  .filter-form {
    flex: 1;
  }

  .form-label {
    color: #858585;
    font-size: 12px;
  }
}
