.btn-submit-article{
  margin-left: 10px;
  border: 1px solid #F57B00;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  color: #393535;
}

.left-content{
  padding: 12px 15px 11px;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  font-size: 12px;
  height: 40px;

  .text-unselected{
    color: lightslategray;
  }

  .text-selected{
    color: #0E132B;
  }
}