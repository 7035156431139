@import '../../../assets/scss/variable';

.form-content {
  border-color: #e9e9e9;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  background-color: white;
  min-height: 50px;
  padding: 30px 30px 40px;

  .form-title {
    font-size: 18px;
    font-weight: bold;
    color: #0e132b;
  }

  .form-data {
    margin-top: 30px;
    margin-right: 25px;

    @media screen and (max-width: 780px) {
      margin-right: 0;
    }

    .form-group {
      margin-bottom: 20px;

      label {
        color: #858585;
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
  }

  .info-box {
    display: flex;
    flex: 1;
    padding: 20px;
    border-radius: 5px;
    border: 1px #eeeeee solid;
    font-size: 12px;

    .title-1,
    .title-2 {
      margin: 0;
    }

    .title-1 {
      flex: 1;
      color: #858585;
    }

    .title-2 {
      flex: 2;
      color: #292222;
    }
  }

  .motor-volta {
    width: 197.51px;
    height: 140px;
  }

  .configuration {
    margin-top: 37px;
  }
}
.btn-form-submit {
  background-color: $primary-800-color;
  color: white;
  font-size: 0.875rem;
  width: 224px;
  height: 46px;
  line-height: 32px;
  border-radius: 30px;
}
.btn-form-submit:hover {
  color: white;
  background-color: $primary-900-color;
}
.btn-form-submit:disabled {
  color: white;
  background-color: $primary-600-color;
  border: 0;
}
