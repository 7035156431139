.wrapper-thumbnail {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  border: 0;
  outline: 0;

  .image-thumbnail {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-zoom {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
  }
}
