.text-background{
  color: #FAFAFB !important;
}

.text-white-100{
  color: #FFFFFF !important;
}

.text-gray-10{
  color: rgba(0, 0, 0, 0.16) !important;
}

.text-black-20 {
  color: #393535 !important;
}

.text-blue-100{
  color: #001B46 !important;
}

.text-black-40{
  color: #8F8F8F !important;
}

.text-black-60{
  color: #858585 !important;
}

.text-blue-80{
  color: #282560 !important;
}

.text-white-80{
  color: #F1F1F1 !important;
}

.text-gray-70{
  color: #7E7E7E !important;
}

.text-gray-80{
  color: #575454 !important;
}

.text-black-100{
  color: #0E132B !important;
}

.text-white-60{
  color: #E3E0E0 !important;
}

.text-white-40{
  color: #DBDBDB !important;
}

.text-green-100{
  color: #069731 !important;
}

.text-orange-100{
  color: #F37A20 !important;
}

.text-red-100{
  color: #F11A1A !important;
}