@import '../../../assets/scss/variable';

@mixin text-select-search-main {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.select-search {
  background-color: aliceblue;
  border-color: #f7f9f7;
  color: #212221;
  font-size: 1rem;
  width: 100%;
  // height: 50%;
  padding: 8px 10px 10px;
  border-radius: 10px;
  text-align: start;
  span {
    // padding: 6px 8px;
    color: #000000;
    background: #ebebeb;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .btn-close-sm {
    width: initial;
    height: initial;
    font-size: large;
  }
  text {
    @include text-select-search-main();
  }
  &.disabled {
    background-color: #f7f9f7;
    border-color: #f7f9f7;
    color: #212221;
  }
}

.select-search::after {
  float: right;
  margin-top: 10px;
}

.select-search:hover {
  background-color: aliceblue !important;
  border-color: #f7f9f7 !important;
  color: #212221 !important;
}

.select-search-menu {
  overflow-y: scroll;
  max-height: 300px;
  width: 100%;
  border-radius: 5px 5px 10px 10px;
  .dropdown-item-text {
    padding-top: 0px;
    margin-top: -10px;
  }
  .title-options {
    @include text-select-search-main();
    color: $primary-800-color;
    margin: 10px 0px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-reset {
      font-size: 10px;
      font-weight: 600;
    }
  }
  .dropdown-header + .txt-no-data {
    text-align: center !important;
    padding: 20px;
  }
  .txt-no-data {
    @include text-select-search-main();
    font-weight: 400;
    color: #7e7e7e;
  }
  .search-input {
    position: relative;
    flex: 1;
    img {
      position: absolute;
      top: 60%;
      z-index: 1;
      left: 15px;
      width: 13px;
      height: 13px;
    }
    input {
      color: $primary-600-color;
      margin-top: initial !important;
      padding-left: 40px;
    }
    input::placeholder {
      color: $primary-400-color;
    }
  }
}

label.label-input-horizontal {
  font-weight: 500 !important;
  color: #212121 !important;
  font-size: 12px;
}
