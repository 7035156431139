@import '../../../assets/scss/variable.scss';

.volta-pagination {
  margin-top: 33px;

  span.btn-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid rgba(217, 217, 217, 1);
  }

  span.btn-pagination:hover {
    background-color: rgba(235, 235, 235, 0.8);
    opacity: 0.8;
  }

  span.btn-pagination.selected {
    background-color: #ebebeb;
  }
}
