.btn-light {
  //   --bs-btn-color: rgba(0, 0, 0, 0.6);
  --bs-btn-bg: #fbfcff;
  --bs-btn-border-color: #d0daf5;
  //   --bs-btn-hover-color: rgba(0, 0, 0, 0.6);
  --bs-btn-hover-bg: #eef0f7;
  --bs-btn-hover-border-color: #d0daf5;
  --bs-btn-focus-shadow-rgb: 194, 202, 215;
  //   --bs-btn-active-color: rgba(0, 0, 0, 0.6);
  --bs-btn-active-bg: #eef0f7;
  --bs-btn-active-border-color: #d0daf5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(0, 0, 0, 0.6);
  --bs-btn-disabled-bg: #d4d9ea;
  --bs-btn-disabled-border-color: #d0daf5;
}

.btn-red {
  --bs-btn-color: #ff5353;
  --bs-btn-bg: #fff0f0;
  --bs-btn-border-color: ##fff0f0;
  --bs-btn-hover-color: #ff5353;
  --bs-btn-hover-bg: #ffe6e6;
  --bs-btn-hover-border-color: #ff5353;
  --bs-btn-focus-shadow-rgb: 255, 0, 0;
  --bs-btn-active-color: #ff5353;
  --bs-btn-active-bg: #ffe6e6;
  --bs-btn-active-border-color: #ff5353;
}
