.text-error {
  font-size: 12px;
  margin-top: 3px;
}

.btn-view-password {
  padding: 2px 4px;
  border-radius: 10px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: 18px;
    height: 18px;
  }
}

.autocomplete {
  position: relative;
  display: inline-block;

  .inputautocomplete-list {
    position: absolute;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border: 0;
    border-radius: 10px;
    z-index: 99;
    left: 0;
    right: 0;
    max-height: 250px;
    overflow-y: auto;
    top: 38px;

    div {
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #d4d4d4;
      font-size: 0.8rem;
    }
    .no-result {
      cursor: initial;
      text-align: center;
      border: 0;
    }
  }
}
