@import '../../../assets/scss/variable.scss';

.sidebar {
  position: fixed;
  padding-left: 0;
  width: $sidebar-width;
  transition: 0.2s;
  bottom: 0;
  height: calc(100vh - #{$header-height});
  overflow-y: auto;
  background: linear-gradient(180deg, #eff3ff 0%, #ffffff 8.62%, #ffffff 100%);
  box-shadow: -10px 14px 20px rgba(0, 0, 0, 0.28);
  z-index: 1;

  @media screen and (max-width: 1024px) {
    background: white;
    height: calc(100vh - #{$header-height} - #{$header-padding-top});
    box-shadow: -10px 14px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px 0px 0px 0px;
  }

  .logout {
    color: #0e132b;
    font-weight: bold;
    cursor: pointer;
  }

  .logo-image {
    width: 113px;
    height: 100px;
  }

  .link {
    text-decoration: none !important;
  }

  .active .side-title {
    font-weight: bold !important;
  }

  .content-sidebar {
    overflow-y: auto;
  }

  .menu {
    width: 100%;
    padding-top: 30px;

    .item.active {
      background-color: $primary-800-color;
      .side-title {
        color: white;
      }
    }
    .item-main {
      cursor: pointer;
      margin: 12px 0;
    }
    .item-group {
      padding-left: 14px;
      padding-right: 12px;
      margin-bottom: 2px;
    }
    .item-child {
      padding: 8px 12px;
      border-radius: 50px;
    }
    .item {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      .edge {
        position: absolute;
        width: 8px;
        height: 14px;
        background: #b5df46;
        box-shadow: 0px 0px 9px #74be1f;
        border-radius: 0px 30px 30px 0px;
        margin-right: 24px;
      }

      .main-title {
        color: $primary-800-color;
        font-weight: 700;
        font-size: 0.875rem;
        margin-bottom: 0;
      }
      .main-icon {
        color: $primary-800-color;
        margin-left: 18px;
        margin-right: 12px;
      }
      .main-icon-chevron {
        color: $primary-800-color;
        margin-left: auto;
        margin-right: 18px;
      }

      .side-title {
        color: $gray-800-color;
        font-size: 0.75rem;
        font-weight: 600;
        font-family: 'Inter';
        margin-bottom: 0;
      }
    }
  }

  .menu::-webkit-scrollbar {
    width: 10px;
  }

  .menu::-webkit-scrollbar-track {
    background: #fff;
  }

  .menu::-webkit-scrollbar-thumb {
    background: #888;
  }

  .menu::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.floating-action {
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    width: 40px;
    height: 40px;
    font-size: 37px;
    font-weight: bold;
    left: -6px;
    cursor: pointer;
  }
}

.floating-action-2 {
  @extend .floating-action;
  @media screen and (max-width: 1024px) {
    top: 10px;
    position: absolute;
    left: 9px;
    cursor: pointer;
  }
}
