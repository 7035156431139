.battery-detail {
  .item {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 18px 14px;
    height: 100%;
  }
  .box-wrapper {
    display: grid;
    gap: 20px;
    max-width: 880px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media screen and(min-width: 576px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and(min-width: 992px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .icon {
        background-color: #f2f6ff;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        padding: 6px 0;

        img {
          margin: auto;
          display: block;
        }
      }
    }
  }
}
